//import { Controller } from "@hotwired/stimulus-webpack-helpers"

class DropzoneController {
  constructor(element) {
    this.element = element
    this.setupFileInput()
    this.bindEvents()
  }

  setupFileInput() {
    // Creëer een verborgen file input
    this.fileInput = document.createElement('input')
    this.fileInput.type = 'file'
    this.fileInput.accept = 'image/*'
    this.fileInput.style.display = 'none'
    this.element.appendChild(this.fileInput)

    // Voeg click event toe aan de dropzone
    this.element.addEventListener('click', () => this.fileInput.click())
    
    // Handle file selectie via de input
    this.fileInput.addEventListener('change', (e) => {
      if (e.target.files.length > 0) {
        this.uploadFile(e.target.files[0])
      }
    })
  }

  bindEvents() {
    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, this.preventDefaults, false)
    })

    ;['dragenter', 'dragover'].forEach(eventName => {
      this.element.addEventListener(eventName, this.highlight.bind(this), false)
    })

    ;['dragleave', 'drop'].forEach(eventName => {
      this.element.addEventListener(eventName, this.unhighlight.bind(this), false)
    })

    this.element.addEventListener('drop', this.handleDrop.bind(this), false)
  }

  preventDefaults(e) {
    e.preventDefault()
    e.stopPropagation()
  }

  highlight(e) {
    this.preventDefaults(e)
    this.element.classList.add('dragover')
  }

  unhighlight(e) {
    this.preventDefaults(e)
    this.element.classList.remove('dragover')
  }

  handleDrop(e) {
    this.unhighlight(e)
    const files = e.dataTransfer.files
    if (files.length > 0) {
      this.uploadFile(files[0])
    }
  }

  uploadFile(file) {
    if (!file.type.match(/^image\//)) {
      alert('Alleen afbeeldingen zijn toegestaan')
      return
    }

    const formData = new FormData()
    formData.append('artwork_concept[image]', file)
    
    const sourceSelect = document.getElementById('artwork_source_select')
    if (sourceSelect && sourceSelect.value) {
      formData.append('artwork_source_id', sourceSelect.value)
    }

    this.element.classList.add('uploading')
    
    fetch('/admin/artwork_concepts/create_with_image', {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      body: formData
    })
    .then(response => {
      if (response.ok) {
        window.location.reload()
      } else {
        return response.text().then(text => {
          try {
            const data = JSON.parse(text)
            throw new Error(data.error || 'Er is een fout opgetreden')
          } catch (e) {
            throw new Error('Er is een fout opgetreden bij het uploaden van de afbeelding')
          }
        })
      }
    })
    .catch(error => {
      console.error('Error:', error)
      alert(error.message)
    })
    .finally(() => {
      this.element.classList.remove('uploading')
    })
  }
}

export default DropzoneController 