class InfiniteScrollController {
  constructor() {
    this.element = document.querySelector('.concepts-feed')
    if (!this.element) return
    
    this.loading = false
    this.page = 1
    this.noMoreContent = false
    this.loadingIndicator = this.element.querySelector('.loading-indicator')
    this.bindEvents()
  }

  bindEvents() {
    window.addEventListener('scroll', this.handleScroll.bind(this))
  }

  handleScroll() {
    if (this.loading || this.noMoreContent) return

    const threshold = 200
    const scrollPosition = window.innerHeight + window.scrollY
    const bottomPosition = document.documentElement.offsetHeight - threshold

    if (scrollPosition >= bottomPosition) {
      this.loadMoreContent()
    }
  }

  loadMoreContent() {
    this.loading = true
    this.loadingIndicator.style.display = 'block'
    this.page += 1

    fetch(`/admin/artwork_concepts/feed?page=${this.page}`, {
      headers: {
        'Accept': 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      return response.json()
    })
    .then(data => {
      if (data.html) {
        // Voeg nieuwe content toe voor de loading indicator
        this.loadingIndicator.insertAdjacentHTML('beforebegin', data.html)
        
        if (!data.has_more) {
          this.noMoreContent = true
          this.loadingIndicator.style.display = 'none'
        }
      } else {
        this.noMoreContent = true
        this.loadingIndicator.style.display = 'none'
      }
    })
    .catch(error => {
      console.error('Error:', error)
      this.page -= 1 // Reset page number on error
    })
    .finally(() => {
      this.loading = false
      if (!this.noMoreContent) {
        this.loadingIndicator.style.display = 'none'
      }
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new InfiniteScrollController()
}) 