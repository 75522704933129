class CommentFormController {
  constructor(element) {
    this.element = element
    this.textarea = element.querySelector('textarea')
    this.form = element.closest('form')
    this.bindEvents()
  }

  bindEvents() {
    this.textarea.addEventListener('keydown', (e) => {
      if (e.key === 'Enter') {
        if (!e.shiftKey) {
          e.preventDefault()
          this.form.requestSubmit()
        }
      }
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.comment-input-group').forEach(element => {
    new CommentFormController(element)
  })
}) 