class ConceptModalController {
  constructor() {
    this.modal = document.getElementById('concept-modal')
    if (!this.modal) return
    
    this.currentConcept = null
    this.allConcepts = []
    this.currentIndex = -1
    this.setupModal()
    this.loadConcepts()
    this.bindEvents()
  }

  setupModal() {
    this.image = this.modal.querySelector('#modal-image')
    this.title = this.modal.querySelector('.concept-title h2')
    this.code = this.modal.querySelector('.concept-title .code')
    this.versionCount = this.modal.querySelector('.version-count span')
    this.sourceInfo = this.modal.querySelector('.source-info span')
    this.finalVersion = this.modal.querySelector('.final-version')
    this.detailsLink = this.modal.querySelector('.details-link')
    this.prevButton = this.modal.querySelector('.nav-button.prev')
    this.nextButton = this.modal.querySelector('.nav-button.next')
    this.commentsList = this.modal.querySelector('.comments-list')
    this.commentForm = this.modal.querySelector('.comment-form')
    
    this.setupCommentForm()
  }

  loadConcepts() {
    this.allConcepts = Array.from(document.querySelectorAll('.concept-card')).map(card => {
      return JSON.parse(card.dataset.concept)
    })
  }

  bindEvents() {
    document.querySelectorAll('.concept-card').forEach(card => {
      card.addEventListener('click', (e) => {
        const isActionButton = e.target.closest('.card-actions')
        if (!isActionButton) {
          e.preventDefault()
          e.stopPropagation()
          const conceptData = JSON.parse(card.dataset.concept)
          this.showModalForConcept(conceptData)
        }
      })
    })

    this.prevButton.addEventListener('click', () => this.showPrevious())
    this.nextButton.addEventListener('click', () => this.showNext())

    document.addEventListener('keydown', (e) => {
      if (!this.modal.style.display || this.modal.style.display === 'none') return

      switch(e.key) {
        case 'ArrowLeft':
          e.preventDefault()
          this.showPrevious()
          break
        case 'ArrowRight':
          e.preventDefault()
          this.showNext()
          break
        case 'Escape':
          e.preventDefault()
          this.hideModal()
          break
      }
    })

    this.modal.querySelector('.modal-close').addEventListener('click', () => {
      this.hideModal()
    })

    window.addEventListener('click', (e) => {
      if (e.target === this.modal) {
        this.hideModal()
      }
    })
  }

  setupCommentForm() {
    this.commentForm.addEventListener('submit', (e) => {
      e.preventDefault()
      const textarea = this.commentForm.querySelector('textarea')
      const comment = textarea.value.trim()
      
      if (comment) {
        this.submitComment(comment)
        textarea.value = ''
      }
    })
  }

  submitComment(body) {
    const versionId = this.currentConcept.selected_version_id
    fetch(`/admin/artwork_concepts/${this.currentConcept.id}/artwork_versions/${versionId}/version_comments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ 
        comment: { body: body }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
        this.addCommentToList({
          id: data.comment.id,
          body: data.comment.body,
          author: data.comment.author,
          created_at: data.comment.created_at,
          can_edit: true,
          version_id: data.comment.version_id
        })
      }
    })
  }

  showModalForConcept(concept) {
    this.currentIndex = this.allConcepts.findIndex(c => c.id === concept.id)
    this.updateNavigationButtons()
    this.showModal(concept)
  }

  showPrevious() {
    if (this.currentIndex > 0) {
      this.currentIndex--
      this.showModal(this.allConcepts[this.currentIndex])
      this.updateNavigationButtons()
    }
  }

  showNext() {
    if (this.currentIndex < this.allConcepts.length - 1) {
      this.currentIndex++
      this.showModal(this.allConcepts[this.currentIndex])
      this.updateNavigationButtons()
    }
  }

  updateNavigationButtons() {
    this.prevButton.style.display = this.currentIndex > 0 ? 'flex' : 'none'
    this.nextButton.style.display = this.currentIndex < this.allConcepts.length - 1 ? 'flex' : 'none'
  }

  showModal(concept) {
    this.currentConcept = concept
    
    this.image.style.opacity = '0'
    this.image.src = concept.image_url
    this.image.onload = () => {
      this.image.style.opacity = '1'
    }

    this.title.textContent = concept.title
    this.code.textContent = concept.code
    this.versionCount.textContent = concept.version_count
    this.sourceInfo.textContent = concept.source || 'Geen bron'
    this.finalVersion.style.display = concept.has_final_version ? 'flex' : 'none'
    this.detailsLink.href = `/admin/artwork_concepts/${concept.id}`
    
    // Render comments
    this.commentsList.innerHTML = ''
    if (concept.comments && concept.comments.length > 0) {
      // Groepeer comments per versie
      const commentsByVersion = {}
      concept.comments.forEach(comment => {
        if (!commentsByVersion[comment.version_id]) {
          commentsByVersion[comment.version_id] = []
        }
        commentsByVersion[comment.version_id].push(comment)
      })

      // Toon comments met versie headers
      Object.entries(commentsByVersion).forEach(([versionId, comments]) => {
        const isCurrentVersion = parseInt(versionId) === concept.selected_version_id
        const versionHeader = `
          <div class="version-header">
            <span class="version-label">${isCurrentVersion ? 'Huidige versie' : 'Eerdere versie'}</span>
          </div>
        `
        this.commentsList.insertAdjacentHTML('beforeend', versionHeader)
        comments.forEach(comment => this.addCommentToList(comment))
      })
    } else {
      this.commentsList.innerHTML = '<div class="no-comments">Nog geen opmerkingen</div>'
    }
    
    this.modal.style.display = 'flex'
    document.body.style.overflow = 'hidden'
  }

  hideModal() {
    this.modal.style.display = 'none'
    document.body.style.overflow = ''
    this.currentConcept = null
    this.currentIndex = -1
  }

  addCommentToList(comment) {
    const commentHtml = `
      <div class="comment" data-comment-id="${comment.id}">
        <div class="comment-header">
          <span class="author">${comment.author}</span>
          <span class="date">${comment.created_at}</span>
          ${comment.can_edit ? `
            <div class="comment-actions">
              <button class="action-link edit">
                <i class="fas fa-edit"></i>
              </button>
              <button class="action-link delete">
                <i class="fas fa-trash"></i>
              </button>
            </div>
          ` : ''}
        </div>
        <div class="comment-body">${comment.body}</div>
      </div>
    `
    
    if (this.commentsList.querySelector('.no-comments')) {
      this.commentsList.innerHTML = ''
    }
    this.commentsList.insertAdjacentHTML('beforeend', commentHtml)
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new ConceptModalController()
}) 