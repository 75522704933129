// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import 'jquery'
import $ from 'jquery';
window.jQuery = $;
window.$ = $;

import 'bootstrap'
import "../controllers/index.js"
import "../controllers/comment_form_controller"
import "../controllers/rating_controller"
import "../controllers/dropzone_controller"
import "../controllers/version_dropzone_controller"
import "../controllers/infinite_scroll_controller"
import "../controllers/concept_modal_controller"

Rails.start()
ActiveStorage.start()


$(function(){
	var total_product = 0;
	var flashFadeOutTimeout = 2500;

	function formatCurrency(amount) {
		var formatted = amount.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
		return '€ ' + formatted;
	}

	function showFlashMessage(message) {
		var template = '<div class="flash-dynamic">' + message + '</div>';
		var speed = 250;
		$('#flash-messages').append(template)
		$('#flash-messages').animate({top: '0'}, speed);
		setTimeout(function() {
			$('#flash-messages').animate({top: '-100px'}, speed, function() {
				$('.flash-dynamic').remove();
			});
		}, flashFadeOutTimeout);
	}

 	var intervalId, timeoutId;

	function updateCounter(counterField, increment) {
		var step = parseInt(counterField.data('step'));
		var max = parseInt(counterField.data('max'));
		var min = parseInt(counterField.data('min'));
		var currentVal = parseInt(counterField.val()) || 0;

		var newVal = currentVal + (increment ? step : -step);
		if (newVal < 0) newVal = 0;
		if (newVal < min) newVal = min;
		if (newVal > max && !counterField.data('unlocked')) newVal = max;

		counterField.val(newVal);
  	};

	function calculateTotal() {
		var total = 0;
		var formatted = 0;
		$('.row[data-product-price]').each(function() {
			var price = parseFloat($(this).data('product-price'));
			var quantity = parseInt($(this).find('.input-field-numeric').val());
			var subtotal = price * quantity;
			total += subtotal;
			total_product += quantity;
			formatted = formatCurrency(subtotal);
			$(this).find('.sub-total').text(formatted);
		});

		//formatted = formatCurrency(total);
		// $('.cart-sub-total').text(formatted);
		// $('.cart-order-total').text(formatted);
	}

	function updateCart() {

		var products = {};
		$('.input-field-numeric').each(function() {
			var productId = $(this).data('product-id');
			var quantity = $(this).val();
			products[productId] = quantity;
		});
		var updateUrl = $(this).data('update-url');

		$.ajax({
			url: updateUrl,
			type: 'POST',
			data: {
				products: products
			},
			success: function(response) {	
				// update divs
				$('.cart-quantity').text(response.quantity);
				$('.cart-header').html(response.header);
				$('.summary').html(response.summary);
				if (total_product > 64) {
					$('.order-block').show();
				} else {
					$('.order-block').hide();
				}
			},
			error: function(jqXHR, textStatus, errorThrown) {
				console.log(textStatus, errorThrown);
			}
		});
	};

	$(document).on("mousedown", ".btn-numeric", function() {
		$(this).addClass('btn-numeric-active');
		if(timeoutId) {
			clearTimeout(timeoutId);
			timeoutId = null;
		}
	});

	$(document).on('change', '.input-field-numeric', function() {
		if(timeoutId) {
			clearTimeout(timeoutId);
		}
		timeoutId = setTimeout(updateCart, 1000);
	});

	$(document).on("mouseup mouseleave", ".btn-numeric", function() {
		$(this).removeClass('btn-numeric-active');
	});

	$(document).on("mousedown", ".plus, .minus", function(e) {
		e.preventDefault();
		var isIncrement = $(this).hasClass('plus');
		var counterField = $(this).parent().siblings('.counter-field');
		updateCounter(counterField, isIncrement);
		intervalId = setInterval(function() {
			updateCounter(counterField, isIncrement);
		}, 200);

		if(timeoutId) {
			clearTimeout(timeoutId);
			timeoutId = null;
		}
	});

	$(document).on("mouseup change", ".plus, .minus", function() {
		calculateTotal();
		clearInterval(intervalId);
		timeoutId = setTimeout(function() {
			updateCart();
		}, 300);
	});

	$('.show-product-details').click(function() {
		var productPageUrl = $(this).data('product-details-url');
		$.ajax({
			url: productPageUrl,
			type: 'GET',
			success: function(response) {
				$('#overlay').html(response).hide().fadeIn();
			},
			error: function(xhr, status, error) {
				console.log(error);
			}
		});
	});

	$(document).on('submit', '.handle-add-to-cart', function(event) {
		event.preventDefault();

		var form = $(this);
		var url = form.attr('action');
		var method = form.attr('method');
		var data = form.serialize();

		$.ajax({
			url: url,
			method: method,
			data: data,
			dataType: 'json',
			success: function(response) {
				$('#overlay').fadeOut();
				var addedProductCount = response.quantity_added;
				showFlashMessage("<i class='fa fa-check fa-large'></i> " + addedProductCount + " product(en) succesvol aan winkelwagen toegevoegd.<br/> <a href='/store/carts'>Ga nu naar het winkelwagentje</a> of ga door met winkelen.");
				$('.cart-quantity').text(response.quantity); // Werk de winkelwagenpictogrammen bij
			},
			error: function(xhr, status, error) {
				alert("Er is een fout opgetreden: " + error); // Toon een foutmelding als er een fout is opgetreden tijdens het toevoegen aan de winkelwagen
			}
			});
	});

	$(document).on("click", ".close-overlay-on-click", function() {
		$('#overlay').fadeOut(300, function() {
			$('#overlay').empty();
		});
	});

	$(document).on("click", ".close-and-go-to-url", function() {
		alert($(this).attr('url'));
		$('#overlay').fadeOut(300, function() {
				$('#overlay').empty();
			});
		});
	});

	$(function(){
  		$.ajaxSetup({
    		headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') }
  	});

	$(".add-to-favorites").click(function() {
		var productDiv = $(this).closest('.product');
		var productId = $(this).data('product-id');
		var url = $(this).data('add-to-favorites-url');
		
		$.ajax({
			url: url,
			method: 'POST',
			data: { id: productId },
			success: function(result) {
				productDiv.toggleClass('favorited');
				result.new_count > 0 ? $('.favorites-count').text(result.new_count) : $('.favorites-count').text('0');
			},
			error: function() {
				alert('Er is een fout opgetreden bij het toevoegen aan favorieten.');
			}
		});
	});
});
