class RatingController {
  constructor() {
    this.bindEvents()
  }

  bindEvents() {
    document.addEventListener('click', (e) => {
      const star = e.target.closest('[data-action="click->rating#rate"]')
      if (star) {
        e.preventDefault()
        this.rate(star)
      }
    })
  }

  rate(starElement) {
    const container = starElement.closest('.rating-container')
    const versionId = container.dataset.versionId
    const score = starElement.dataset.score
    const conceptId = window.location.pathname.split('/')[3] // Haal concept ID uit URL
    
    fetch(`/admin/artwork_concepts/${conceptId}/artwork_versions/${versionId}/artwork_version_ratings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({ score })
    })
    .then(response => response.json())
    .then(data => {
      if (data.error) {
        alert(data.error)
        return
      }
      
      // Update UI
      const stars = container.querySelectorAll('.fa-star')
      stars.forEach((star, index) => {
        star.classList.remove('fas', 'far', 'selected')
        if (index < data.user_rating) {
          star.classList.add('fas', 'selected')
        } else {
          star.classList.add('far')
        }
      })
      
      container.querySelector('.score').textContent = data.average
    })
    .catch(error => {
      console.error('Error:', error)
      alert('Er is een fout opgetreden bij het opslaan van je rating.')
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  new RatingController()
}) 